a,
h2,
h3,
h4,
h5,
h6,
p,
ul,
table,
.markdown-content {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto;

  color: var(--text-color);
}

hr {
  border-bottom: var(--border-default);
}

strong {
  font-weight: var(--font-weight-semibold);
}
