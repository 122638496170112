explorer-request-bar {
  background-color: var(--bg-color);
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-3);
  padding: var(--spacing-4) 0 0;
  margin-block-end: var(--spacing-8);
  position: sticky;
  top: var(--spacing-16);
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    height: var(--spacing-8);
    top: 100%;
    width: 100%;
    left: 0;
    background-image: linear-gradient(to bottom, color-mix(in oklch, var(--bg-color), transparent 66%), transparent);
  }

  .dropdown-title,
  >.button,
  .request-url {
    min-height: var(--spacing-10);
  }

  .request-url {
    border: var(--border-default);
    border-radius: var(--border-radius-medium);
    display: flex;
    align-items: center;
    gap: var(--spacing-3);
    flex-grow: 1;
    padding-inline-end: var(--spacing-4);

    .operation-verb {
      align-self: stretch;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      display: flex;
      align-items: center;
      font-size: var(--text-sm);
      padding-inline: var(--spacing-4);
      margin: -1px;

      &.verb {
        background: color-mix(in oklch, var(--doc-nested-background-color), var(--black) 1%);
        border: 0;
        color: var(--gray-50);
        margin: 0;
      }
    }

    .operation-path {
      margin-inline-end: auto;
      padding-block: var(--spacing-2);
    }

    .dropdown {
      margin-inline-start: auto;
    }

    .menu-dropdown {
      right: var(--spacing-3-minus);
    }

    .server-button {
      span {
        margin-right: var(--spacing-8);
      }

      svg {
        height: auto;
        width: auto;

        &:is(button:not(.selected) > *) {
          display: none;
        }
      }
    }
  }

  .share-context {
    background-color: var(--blue-10);
    border: var(--blue-20) var(--spacing-px) solid;
    padding: var(--spacing-4) var(--spacing-3);
    width: 100%;
    border-radius: var(--spacing-1);

    a {
      text-decoration: underline;
    }
  }

  @media (width < 850px) {
    .request-url {
      width: 100%;

      .operation-path {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .dropdown {
        max-width: 50%;

        .dropdown-title {
          padding: 0;

          [data-dropdown-target="current"] {
            text-overflow: ellipsis;
            overflow: hidden;
          } 
        }
      }

      span[data-explorer-share-target="server"] {
        max-width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media (width < 500px) {
    #operations-dropdown {
      width: 100%;
    }

    .dropdown-title [data-dropdown-target="current"] {
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
    }

    .request-url {
      .show-server {
        display: none;
      }
    }
  }
}
