a.doc-back-link:not([href]) {
  display: none;
}

a.doc-back-link {
  position: absolute;
  right: 0;
  top: var(--spacing-05);
  z-index: 3;
}

.doc-back-link-container {
  position: relative;
  z-index: 2;
}
