portal-example {
  --example-color: var(--gray-10);
  --example-header-color: color-mix(in srgb, var(--example-color), var(--blue-70) 10%);
  --example-button-icon: url("images/icons/copy-example.svg");
  container-type: inline-size;
  color: var(--example-text-color);

  &:not([data-example-theme="custom"]):is(.dark-theme *) {
    --example-color: var(--gray-90);
    --example-header-color: var(--slate-90);
    --example-button-icon: url("images/icons/copy-example-white.svg");
  }

  &:is([data-example-theme="dark"]):is(.dark-theme *) {
    --example-color: var(--gray-80);
    --example-header-color: var(--gray-90);
  }

  &:not([hidden]) {
    display: flex;
    flex-direction: column;
  }

  &.code-sample {
    z-index: 15;
  }

  header {
    background-color: var(--example-header-color);
    border-radius: var(--border-radius-medium) var(--border-radius-medium) 0 0;
    display: flex;
    gap: var(--spacing-3);
    justify-content: space-between;
    font-size: var(--text-sm);
    padding: var(--spacing-3);
    position: relative;
    top: 0;

    &:only-child {
      border-radius: var(--border-radius-medium);
    }

    .title {
      display: flex;
      flex-grow: 1;
      gap: var(--spacing-1) var(--spacing-3);
      justify-content: space-between;
      width: 100%;
    }

    span[dir="rtl"] {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-inline-start: auto;
    }

    .dropdown {
      position: initial;
    }

    .dropdown-title {
      gap: var(--spacing-2);
      padding-block: 0;
      height: auto;
    }

    .menu-dropdown {
      right: var(--spacing-2);

      &:is(.dropdown[aria-expanded="true"] *) {
        top: calc(100% - var(--spacing-2));
      }

      a {
        gap: var(--spacing-3);
        max-width: calc(100cqi - var(--spacing-9));

        &::after {
          flex-shrink: 0;
        }
      }

      span {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .copy, .copy:hover {
      background: var(--example-button-icon) center center no-repeat;
      border: 0;
      aspect-ratio: 1 / 1;
      height: var(--spacing-5);
      padding: 0;
    }

    .button, .button:hover {
      color: currentColor;
    }
  }

  .placeholder {
    padding: var(--spacing-3);
  }

  .example-body {
    background-color: var(--example-color);
    border-radius: 0 0 var(--border-radius-medium) var(--border-radius-medium);
    height: 100%;
    overflow: auto;
    scrollbar-color: var(--example-header-color) transparent;
  }

  .example-content {
    height: 100%;
    padding: var(--spacing-3);

    pre {
      flex-grow: 1;
      font-size: var(--text-xs);
      margin: 0;

      code {
        color: currentColor;
        overflow: initial;
      }
    }

    :is([data-doc-examples-target="example"]) {
      display: none;

      &.active,
      &:only-child {
        display: initial;
      }
    }

    .language-curl::before {
      content: "$ ";
    }
  }

  &[data-example-theme="dark"],
  &[data-example-theme="custom"] {
    --example-button-icon: url("images/icons/copy-example-white.svg");
    --example-text-color: var(--white);

    code > span {
      font-weight: var(--font-weight-semibold);
    }
  }

  &[data-example-theme="dark"] {
    --example-color: var(--gray-70);
    --example-header-color: var(--gray-80);

    code.hljs [class^="hljs-"] {
      color: var(--black);
      font-weight: 600;
    }
  }

  &[data-example-theme="custom"] {
    --example-color: var(--doc-base-color, var(--base-color));
    --example-header-color: color-mix(in srgb, var(--example-color), var(--gray-70) 65%);

    /* hack to turn the text to white or black depending on the background color */
    pre:not(:has(code.hljs)), /* fix to make it work under safari */
    code.hljs {
      color: var(--example-color, var(--example-text-color));
      filter: invert(1)
      grayscale(1)
      contrast(9000);
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      mix-blend-mode: luminosity;
    }

    code.hljs {
      --doc-code-color: var(--black);

      [class^="hljs-"] {
        color: currentColor;
        font-weight: 600;
      }
    }
  }

  .example-description {
    --symbol-padding: 1em;
    margin-block-end: var(--spacing-3);
    padding-inline-start: var(--symbol-padding);
    position: relative;

    &::before {
      content: "##############################################################";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: var(--symbol-padding);
      overflow-y: hidden;
      overflow-wrap: anywhere;
    }

    &.language-json::before {
      content: "//////////////////////////////////////////////////////////////";
    }

    &.language-xml::before {
      content: "<!--";
    }

    &.language-xml {
      --symbol-padding: 2em;
      padding-inline-start: 2em;

      &::after {
        content: "-->";
      }
    }
  }
}
