#response-dialog {
  --dialog-width: 960px;
  --dialog-height: 720px;
  border-radius: var(--border-radius-medium);
  height: min(var(--dialog-height), calc(100% - 64px));
  width: min(var(--dialog-width), calc(100% - 64px));

  portal-example {
    height: 100%;
  }

  header .expand {
    display: none;
    pointer-events: none;
  }

  header .close-dialog svg {
    height: 14px;
    position: relative;
    top: -1px;
  }
} 
