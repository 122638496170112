.navigation__resources {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  margin-block-end: var(--spacing-10);
  padding-inline: var(--spacing-4);
}

.navigation__resource-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-1-5) var(--spacing-3);
  border-radius: var(--border-radius-medium);
}

.navigation__resource .navigation__resource-link.active {
  background-color: color-mix(in srgb, var(--doc-base-color), transparent 90%);
  color: var(--doc-base-color);
}

.navigation__resource-link svg {
  transform: rotate(-90deg);
}

.navigation__resource-link.active svg {
  transform: none;
}

@media (max-width: 850px) {
  .navigation__resources {
    padding: 0 calc(var(--spacing-1) + var(--spacing-px));
  }
}
