select {
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAMAAACzvE1FAAAADFBMVEUzMzMzMzMzMzMzMzMKAG/3AAAAA3RSTlMAf4C/aSLHAAAAPElEQVR42q3NMQ4AIAgEQTn//2cLdRKppSGzBYwzVXvznNWs8C58CiussPJj8h6NwgorrKRdTvuV9v16Afn0AYFOB7aYAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 8px 10px;
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--input-box-shadow);
  box-sizing: border-box;
  color: var(--title-color);
  font-family: var(--font-family);
  font-size: 0.875rem;
  height: var(--spacing-8);
  margin-bottom: var(--spacing-3);
  padding-inline: var(--spacing-3) var(--spacing-6);
  transition: border-color var(--base-duration) ease;
  width: 100%;
}

.input {
  appearance: none;
  background-color: var(--bg-color);
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--input-box-shadow);
  box-sizing: border-box;
  color: var(--text-color);
  max-width: var(--input-max-width);
  padding: var(--spacing-2);
  transition: border-color var(--base-duration) var(--base-timing);
  width: 100%;
}

.input:hover {
  border-color: var(--input-border-color-hover);
}

.input:focus {
  border-color: var(--input-border-color-focus);
  box-shadow: var(--input-box-shadow-focus);
  outline: none;
}

.input.disabled {
  background-color: var(--input-bg-color-disabled);
  cursor: not-allowed;
}

.input.disabled:hover {
  border: var(--border-default);
}

.input:placeholder {
  color: var(--text-color-secondary);
}

label {
  font-size: 14px;
}

.input-group {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-1);
}

input {
  font-size: 0.875rem;
  height: var(--spacing-8);
  padding-inline: var(--spacing-3);
  background: transparent;
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  color: var(--title-color);
  font-family: var(--font-family);
  margin-right: -1px;

  &::placeholder {
    color: var(--text-color-secondary);
    font-size: inherit;
  }

  &:focus-visible {
    outline: 0;
  }

  &[type=checkbox] {
    height: initial;
  }
}
