.markdown-content {
  --flow-space: var(--spacing-4);
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  hyphens: auto;

  [id] {
    scroll-margin-top: var(--spacing-12-minus);
  }

  position: relative;

  .code-block {
    background-color: var(--panel-bg-color);
    border-radius: var(--border-radius-medium);
    display: block;
    margin-bottom: var(--spacing-6);
    padding: var(--spacing-6);
    white-space: pre-wrap;
  }

  hr {
    margin: var(--spacing-3) 0;
  }

  h2 {
    font-size: var(--text-2xl);
    font-weight: var(--font-weight-normal);
  }

  h3 {
    font-size: var(--text-l);
    font-weight: var(--font-weight-semibold);
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    --flow-space: var(--spacing-6);
  }

  h4 {
    font-size: var(--text-base);
    font-weight: var(--font-weight-semibold);
  }

  h5,
  h6 {
    font-size: 1.1em;
    font-weight: var(--font-weight-medium);
  }

  h5,
  h6 {
    font-style: italic;
  }

  ul, ol {
    list-style-position: outside;
    padding-left: var(--spacing-6);
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-alpha;
    }
  }

  ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;
    }
  }

  li {
    margin-block-start: var(--spacing-3);
  }

  blockquote {
    border-left: solid 5px var(--panel-bg-color);
    padding-left: var(--spacing-3);
  }
}
