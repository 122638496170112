.combinator {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-3);
  scroll-margin-top: var(--spacing-8-minus);
  max-width: calc(100vw - var(--doc-inline-spacing) * 2);
}

.combinator__header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: var(--spacing-4);
  color: var(--text-color);
  position: relative;
}

.combinator__header::after {
  border-bottom: var(--border-default);
  bottom: -1px;
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
}

.combinator__type {
  margin-block-end: 0;
  font-size: var(--text-sm);
  flex-shrink: 0;
}

.combinator .tabs {
  --gradient-bg-color: var(--bg-color);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-grow: 1;
  overflow-x: auto;
  background:
    linear-gradient(to right, var(--gradient-bg-color), var(--gradient-bg-color), var(--transparent-bg-color) calc(var(--scroll-shadow-size) * 2)),
    radial-gradient(farthest-side at 0 50%, var(--scroll-shadow-gradient), var(--transparent-bg-color)),
    linear-gradient(to left, var(--gradient-bg-color), var(--gradient-bg-color), var(--transparent-bg-color) calc(var(--scroll-shadow-size) * 2)),
    radial-gradient(farthest-side at 100% 50%, var(--scroll-shadow-gradient), var(--transparent-bg-color)) 100%;
  background-repeat: no-repeat;
  background-attachment: local, scroll, local, scroll;
  background-size: 100% 100%, var(--scroll-shadow-size) 100%, 100% 100%, var(--scroll-shadow-size) 100%;
  height: 100%;
}

.expandable-properties-list:not(.active)[open] .combinator .tabs {
  --gradient-bg-color: var(--doc-nested-background-color);
}

.expandable-properties-list.active[open] .combinator .tabs {
  --gradient-bg-color: var(--bg-color);
}

.combinator .tab {
  color: var(--hint-text-color);
  padding: var(--spacing-2);
  position: relative;
  flex-wrap: nowrap;
  flex-shrink: 0;

  > .property-type {
    background: 0;
    padding-inline-end: revert;

    &:hover {
      &::before,
      &::after {
        content: none;
      }
    }
  }

  > .pill-deprecated {
    padding: 0.125em var(--spacing-2) 0;
    top: 0;
  }
}

.combinator .tab[aria-selected="true"] {
  border-bottom: 2px solid var(--doc-base-color);
  color: var(--title-color);
}

.combinator > .properties-list {
  margin-block-start: 0;
  border: 0;
}

.combinator > .properties-list > ul > li:first-child {
  border-top: 0;
}

.combinator > [data-tabs-target="panel"] {
  border: 0;
  display: flex;
  flex-direction: column;
  padding: 0;
  row-gap: var(--spacing-3);

  > .properties-list > ul:first-child {
    border-block-start: 0;
  }
}

.combinator > [data-tabs-target="panel"][aria-hidden="true"] {
  display: none;
}
