.portal-explorer {
  font-size: var(--text-sm);

  portal-header {
    position: sticky;
    top: 0;
  }

  portal-resource-title {
    margin-inline-end: auto;
  }

  .explorer-content {
    @media (max-width: 850px) {
      grid-template-columns: 1fr min(var(--wrapper-width), calc(100% - 48px)) 1fr;
      padding-block: 0 var(--spacing-16);
      scroll-padding-top: 7.9rem;
      z-index: -1;
    }

    .doc-section-title {
      margin-block-end: var(--spacing-4);
    }

    .doc-section--column {
      row-gap: 0;
    }

    [data-controller="clearable-form"]:not(.clearable) .clear-form-link {
      display: none;
    }

    .properties-list-header {
      border-block-end: var(--border-default);
      display: flex;
      flex-direction: column;
      gap: var(--spacing-3);
      padding-block-end: var(--spacing-3);

      &:not(:first-of-type) {
        margin-block-start: var(--spacing-8);
      }
    }

    .properties-list-content {
      column-gap: var(--spacing-2);
      justify-content: start;
    }

    .properties-list {
      --even-level-color: var(--doc-base-color);
      --odd-level-color: var(--purple-60);

      ul {
        border: 0;
        padding-block: var(--spacing-3);
        display: flex;
        flex-direction: column;
        gap: var(--spacing-3);
      }

      :where(.property) {
        border: 0;
        gap: var(--spacing-2);
        padding: 0;

        select {
          margin: 0;
        }
      }

      :where(.properties-list) {
        border-inline-start: 1px solid var(--border-color-default);
        padding-inline-start: var(--spacing-6);

        ul {
          padding: 0;
        }
      }

      &:is([data-tabs-target="panel"] > *) {
        border: 0;
        padding: 0;
      }
    }

    .operation-section {
      flex-grow: 1;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - var(--spacing-6)));
      gap: var(--spacing-12);
      position: relative;

      > .flex-column {
        padding-block-end: var(--spacing-17);
      }
  
      @media (max-width: 850px) {
        display: flex;
        flex-direction: column;
      }

      .properties-list-header {
        margin-block-start: var(--spacing-5);
      }
    }

    .operation-header {
      gap: var(--spacing-4);
      padding-block-end: var(--spacing-3);
    }
  
    .operation-header-content {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
  
      @media (max-width: 500px) {
        flex-direction: column;
        gap: var(--spacing-3);
      }
    }
  
    .operation-title {
      font-size: 1.5em;
      font-weight: var(--font-weight-medium);
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-3);
      align-items: center;
      justify-content: space-between;
    }
  
    .request-preview {
      .sticky-scrollable {
        min-height: calc(100vh - var(--spacing-40));
        padding-block-end: var(--spacing-4);
        top: var(--spacing-38);
  
        &::after {
          position: fixed;
          bottom: 0;
        }
  
        @media (max-width: 850px) {
          min-height: revert;
        }
      }

      copy-field {
        white-space: nowrap;
  
        span {
          overflow-x: hidden;
          padding-block: var(--spacing-1);
          text-overflow: ellipsis;
        }
      }
    }
  
    .attribute-container {
      display: flex;
      flex-direction: column;
      row-gap: var(--spacing-6);
    }
  
    .attributes {
      display: flex;
      flex-wrap: wrap;
  
      > .remove {
        align-self: center;
        margin-inline-start: var(--spacing-1);
  
        svg {
          height: var(--spacing-4);
        }
      }
    }

    .property-doc-button:hover {
      text-decoration: none;

      label {
        pointer-events: none;
      }
    }
  
    .property-name {
      margin: 0;
    }

    .combinator {
      width: 100%;
    }
  
    .doc-section-examples {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-6);
    }
  
    portal-example {
      height: calc((100vh - 200px) / 2);
    }
  }

  .anchor-link::before {
    content: none;
  }
}

.explorer-banner {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-3) var(--spacing-4);
  border-radius: var(--spacing-1);
  border: 1px solid var(--blue-20, #e6f6fc);
  background: var(--blue-10, #f5fbff);
}

.explorer-banner-link {
  text-decoration: underline;
}
