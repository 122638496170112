mark {
  background: var(--doc-base-dark-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.markdown-content a:not(.button):not(.anchor-link) {
  color: var(--doc-base-color);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.markdown-content p {
  line-height: 2;
}

.markdown-content p:last-child {
  margin-block-end: 0;
}
