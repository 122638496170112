.anchor-link::before,
.dismiss-highlight {
  background: var(--bg-color) url("../../images/icons/link-gray.svg") center center no-repeat;
  background-size: 75%;
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  height: var(--spacing-5);
  left: var(--spacing-8-minus);
  position: absolute;
  right: var(--spacing-8-minus);
  width: var(--spacing-5);
  z-index: 1;
}

.anchor-link {
  &:is(.doc-section-title > *) {
    align-items: center;
    display: flex;
  }

  &::before {
    content: "";
    opacity: 0;
  }

  &:is(.operation-title:has(.button) > *):hover::before {
    top: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &:is(.expandable-properties-list *)::before {
    left: var(--spacing-9-minus);
  }
}

.highlight-container {
  position: relative;

  .dismiss-highlight {
    display: none;
    text-indent: calc(infinity * -1px);
    transform: rotate(90deg);
    z-index: 2;

    &:hover {
      border-color: var(--input-border-color-hover);
    }

    &:is(.highlight-container.shared > *) {
      display: block;
    }

    &:is(.highlight-container.shared:hover > *) {
      background: var(--bg-color) url("../../images/icons/highlight-cross.svg") 1px 1px;
      background-size: 90%;
    }

    &:is(.combinator > *) {
      top: var(--spacing-2);
    }

    &:is(details .combinator > *) {
      left: var(--spacing-9-minus);
    }

    &:is(.markdown-content > *) {
      top: var(--spacing-4-minus);
    }

    &:is(.changelog-event > *) {
      top: var(--spacing-14);
    }

    &:is(.expandable-properties-list *) {
      left: var(--spacing-6-minus);
    }
  }

  &.shared {
    &::after {
      --padding-block: var(--spacing-4);
      --padding-inline: var(--spacing-6);
      border: 1.5px solid color-mix(in srgb, var(--doc-base-color), transparent 50%);
      border-radius: var(--border-radius-medium);
      bottom: calc(var(--padding-block) * -1);
      box-sizing: content-box;
      content: "";
      display: block;
      left: calc(var(--padding-inline) * -1);
      padding: var(--padding-block) var(--padding-inline);
      pointer-events: none;
      position: absolute;
      top: calc(var(--padding-block) * -1);
      width: 100%;
    }
  
    &.properties-list::after {
      padding-bottom: 0;
    }
  
    &.property::after {
      --padding-block: 0;
      bottom: 0;
      top: -1px;
    }
  
    &.combinator::after {
      --padding-block: 0;
      bottom: var(--spacing-6-minus);
      top: 0;
    }

    &.combinator:is(details *)::after {
      bottom: calc(var(--spacing-4) * -1);
      top: var(--spacing-1-minus);
    }

    &.combinator:is(details *)::after {
      bottom: var(--spacing-3-minus);
      top: var(--spacing-1-minus);
      left: var(--spacing-6-minus);
      padding-inline: var(--spacing-6);
    }

    &:is(.expandable-properties-list *)::after {
      left: -15px;
      padding-inline: 14px;
    }
  }
}
