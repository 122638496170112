.navigation__operations {
  display: none;
  flex-direction: column;
  margin-block-start: var(--spacing-3);
  padding-inline: var(--spacing-4) var(--spacing-3);
}

.navigation__resource-link {
  color: var(--text-color);
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

.active.navigation__resource-link + .navigation__operations {
  display: flex;
}

.navigation__operation-link {
  border-left: 1.5px solid var(--bg-color-secondary);
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  min-height: var(--spacing-8);
  padding: var(--spacing-2) 0 var(--spacing-2) var(--spacing-4);
  font-size: var(--text-sm);
}

.active.navigation__operation-link {
  border-left: 1.5px solid var(--doc-base-color);
  color: var(--doc-base-color);
  width: 100%;
}

.active.navigation__operation-link::before {
  background-color: var(--doc-base-color);
}

.navigation__operation .operation-verb {
  background-color: transparent;
  border: 0;
  margin-inline-start: auto;
  padding: 0;
}
