.no-js .doc-content .operation[aria-current="true"] {
  margin-block: var(--doc-block-spacing);
}

.operation-header {
  display: flex;
  flex-direction: column;
  gap: var(--base-gap);
  container: operation-header / inline-size;
}

h2.operation-title {
  align-items: baseline;
  display: flex;
  gap: var(--spacing-3);
  margin-bottom: 0;
  font-size: 1.5em;
  font-weight: var(--font-weight-medium);

  .title-badges {
    display: flex;
    gap: var(--spacing-3)
  }

  .badge {
    font-size: var(--text-sm);
    font-weight: var(--font-weight-semibold);
    padding: var(--spacing-1) var(--spacing-3);
    text-transform: revert;
    white-space: nowrap;

    &.beta {
      background-color: var(--purple-10);
      border-radius: var(--border-radius-medium);
      height: fit-content;
    }
  }

  .button {
    margin-inline-start: auto;
  }

  @container operation-header (width < 500px) {
    flex-wrap: wrap;

    .button {
      margin-inline-start: initial;
    }
  }
}
