@import "doc-compare.css";
@import "doc-container.css";
@import "doc-content.css";
@import "doc-loading-state.css";
@import "doc-markdown.css";
@import "doc-navigation.css";
@import "doc-operation-verbs.css";
@import "doc-section.css";
@import "doc-preview.css";
@import "call-to-action.css";
@import "forms.css";
@import "markdown-content.css";
@import "multiple-content.css";
@import "tooltipped.css";
@import "typography.css";
@import "pill.css";
@import "security-badge.css";
@import "navigation-resource.css";
@import "navigation-operation.css";
@import "hljs-overrides.css";
@import "operation.css";
@import "operation-group.css";

/* it's important to keep this import after the property one because of style conflicts.
   will move to cascade layers once they don't conflict with tailwind @layer directive */
@import "properties-list.css";
@import "expandable-properties-list.css";
@import "property.css";
@import "alternative.css";
