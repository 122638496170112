.properties-list {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-3);
  scroll-margin-top: var(--spacing-10-minus);

  > ul {
    border-top: var(--border-default);
  }
}

.properties-list-title {
  font-size: var(--text-l);
  font-weight: var(--font-weight-medium);
  margin: 0;
}

.properties-list-content {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.schema-collection {
  width: 100%;

  > .property-name {
    border-block-end: var(--border-default);
    font-size: 1.125rem;
    font-weight: var(--font-weight-medium);
    padding-block-end: var(--spacing-3);
  }
}

.single-schema h5 a {
  font-size: var(--text-l);
  text-transform: initial;
}

.single-schema .property {
  border-top: 0;
  padding: 0;
}

.single-schema .property-name {
  text-transform: uppercase;
  font-size: var(--text-xs);
  color: var(--text-color-secondary);
}

.single-schema .property-type {
  display: none;
}

.single-schema .markdown-content {
  padding-block-start: var(--spacing-4);
  border-top: var(--border-default);
}
