.doc-section {
  --grid-ratio: 1fr;

  border-top: var(--border-default);
  display: flex;
  flex-direction: column;
  gap: var(--doc-block-spacing) var(--base-gap);
  padding: var(--doc-block-spacing) var(--doc-inline-spacing);
  position: relative;
  scroll-margin-top: calc(var(--doc-block-spacing) * -1);
}

.no-js .doc-content {
  .doc-section {
    border: 0;

    &:not([aria-current="true"]) {
      gap: 0;
      padding-block: 0;
    }
  }

  turbo-frame  {
    display: none;
  }
}

.doc-section--column {
  display: flex;
  flex-direction: column;
  gap: var(--base-gap) var(--doc-inline-spacing);
}

.doc-section--grid {
  display: grid;
  gap: var(--base-gap) var(--doc-inline-spacing);
  grid-template-columns: var(--grid-ratio);
  position: relative;

  > :last-child {
    margin-bottom: 0;
  }
}

#doc-introduction {
  border: 0;
  z-index: 1;

  &:not(turbo-frame), &:is(turbo-frame[complete]) {
    display: block;
  }

  h1 {
    margin-bottom: var(--spacing-12);
  }

  .doc-section-copy {
    display: block;
    min-height: 90px;
  }

  .doc-section-examples {
    float: right;
    margin-inline-start: var(--spacing-8);
    max-width: 525px;
    order: 0;
    position: relative;
    top: 0;
    width: clamp(250px, 45%, 525px);
    z-index: 1;
  }

  + .doc-section {
    clear: both;
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;

    .doc-section-copy {
      min-height: auto;
      order: 0;
    }

    .doc-section-examples {
      margin: 0 0 var(--base-gap);
      order: 1;
      width: 100%;
    }
  }
}

body:not(.no-js) .doc-content #doc-introduction {
  padding-bottom: var(--doc-block-spacing);
}

#doc-servers table {
  margin-top: 0;
}

/* Ideally we could target only :-moz-only-whitespace divs but the
existing :empty CSS selector doesn't select nodes with a child
with whitespace only text...
We could replace this with :empty selector only but with CSS 4
cf https://developer.mozilla.org/fr/docs/Web/CSS/:-moz-only-whitespace */
turbo-frame.doc-section:not([disabled]):not([complete]):not([busy]) {
  min-height: 50vh;
}
turbo-frame.operation:not([disabled]):not([complete]):not([busy]) {
  min-height: 100vh;
}

turbo-frame.missing-frame {
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  color: var(--text-color-warning);
  grid-template-columns: 1fr;
  padding: var(--spacing-3) var(--spacing-4);
}

.doc-section-title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--spacing-3);
  margin: 0;
  position: relative;
  color: var(--title-color);
  font-size: 2em;
  font-weight: var(--font-weight-medium);
}

.doc-section-examples {
  overflow: scroll;
  width: 100%;
}

.doc-section-examples-content {
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
}

@media (min-width: 850px) {
  .doc-section {
    --grid-ratio: minmax(50%, 2fr) minmax(250px, 525px);
  }

  .doc-operation-summary {
    grid-column: 2 / -1;
    grid-row: 1 / span 2;
  }

  .doc-section-copy {
    grid-column: 1;
  }

  #doc-introduction .doc-section-title {
    grid-column: span 2;
  }

  .doc-section:last-child > .doc-section-content:last-child {
    min-height: calc(100vh - var(--doc-nav-height) - (var(--doc-block-spacing) * 2));
  }

  .doc-section-examples {
    overflow: hidden;
    position: sticky;
    top: var(--base-gap);
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: var(--spacing-5);

    &.sticky-scrollable {
      max-height: calc(100vh - (var(--base-gap) * 2));

      &:after {
        content: "";
        position: absolute;
        height: var(--spacing-8);
        background-image: linear-gradient(
          to bottom,
          var(--transparent-bg-color) 10%,
          var(--bg-color)
        );
        width: 100%;
        bottom: var(--spacing-4);
        z-index: 100;
      }
    }

    .doc-section-examples-content {
      height: 100%;
      overflow: auto;

      > .doc-example {
        flex-shrink: 0;
      }
    }
  }
}
