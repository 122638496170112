.expandable-properties-list {
  --list-bg-color: var(--bg-color);
  background-color: var(--list-bg-color);
  border: var(--border-default);
  border-radius: var(--border-radius-medium);
  max-width: 100%;
  position: relative;
  width: fit-content;

  &[aria-hidden="true"] {
    display: none;
  }

  .properties-list {
    > .markdown-content {
      border-block-start: var(--border-default);
      padding-block-start: var(--spacing-3);
      padding-inline: var(--spacing-3);
    }
  }
}

.expandable-properties-list[open] {
  width: 100%;
}

.expandable-properties-list:not(.active)[open] {
  --list-bg-color: var(--doc-nested-background-color);
  width: 100;
  display: block;
}

.expandable-properties-list.active[open] {
  --list-bg-color: var(--bg-color);
}

.expandable-properties-list.hidden {
  display: none;
}

.expandable-properties-list summary {
  align-items: baseline;
  background-image: url('data:image/svg+xml, <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.02575 10.2636C5.02863 10.4292 5.09643 10.587 5.21454 10.7031C5.33265 10.8192 5.49163 10.8842 5.65723 10.8842C5.82283 10.8842 5.9818 10.8192 6.09991 10.7031C6.21803 10.587 6.28582 10.4292 6.2887 10.2636L6.2887 6.13182L10.4205 6.13182C10.588 6.13188 10.7487 6.06539 10.8672 5.94696C10.9858 5.82854 11.0524 5.66789 11.0524 5.50035C11.0525 5.33282 10.986 5.17212 10.8676 5.05361C10.7491 4.9351 10.5885 4.86849 10.421 4.86843L6.28825 4.86933L6.2887 0.737072C6.2887 0.569476 6.22212 0.408745 6.10361 0.290237C5.98511 0.17173 5.82437 0.105153 5.65678 0.105153C5.48918 0.105153 5.32845 0.17173 5.20995 0.290237C5.09144 0.408745 5.02486 0.569475 5.02486 0.737071L5.0262 4.86933L0.893947 4.86888C0.726411 4.86894 0.56576 4.93555 0.447336 5.05406C0.328912 5.17256 0.262415 5.33326 0.262475 5.5008C0.262534 5.66833 0.329145 5.82898 0.447652 5.94741C0.56616 6.06583 0.726857 6.13233 0.894393 6.13227L5.02575 6.13182L5.02575 10.2636Z" fill="rgb(106, 108, 109)"/></svg>');
  background-position: 11px 12px;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  cursor: pointer;
  column-gap: 0.25em;
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-2);
  overflow: hidden;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-2) var(--spacing-8);

  &:is(.expandable-properties-list[open] > *) {
    background-image: url('data:image/svg+xml, <svg height="9" width="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.934985 0.934985C1.18163 0.688338 1.58153 0.688338 1.82817 0.934985L4.75 3.85681L7.67183 0.934985C7.91847 0.688338 8.31837 0.688338 8.56501 0.934985C8.81166 1.18163 8.81166 1.58153 8.56501 1.82817L5.64319 4.75L8.56501 7.67183C8.81166 7.91847 8.81166 8.31837 8.56501 8.56501C8.31837 8.81166 7.91847 8.81166 7.67183 8.56501L4.75 5.64319L1.82817 8.56501C1.58153 8.81166 1.18163 8.81166 0.934985 8.56501C0.688338 8.31837 0.688338 7.91847 0.934985 7.67183L3.85681 4.75L0.934985 1.82817C0.688338 1.58153 0.688338 1.18163 0.934985 0.934985Z" fill="rgb(106, 108, 109)"/></svg>');
    background-position: 12px 13px;
    background-size: 9px 9px;
  }

  > .property-type {
    color: var(--text-color-secondary);
    font-size: var(--text-xxs);
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
  }
}

.expandable-properties-list:not([open]) > summary .hide {
  display: none;
}

.expandable-properties-list[open] > summary > .show {
  display: none
}

.expandable-properties-list .property {
  box-sizing: border-box;
  padding: var(--spacing-3);
}
