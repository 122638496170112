.tooltipped {
  --tooltip-spacing: calc(100% + var(--spacing-1));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    -webkit-font-smoothing: subpixel-antialiased;
    background: var(--gray-80);
    border-radius: 3px;
    color: var(--white);
    content: attr(aria-label);
    font-size: 0.7rem;
    letter-spacing: normal;
    padding: var(--spacing-1) var(--spacing-3);
    pointer-events: none;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    white-space: pre;
    word-wrap: break-word;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  }

  &:active:after {
    opacity: 0;
  }

  &:hover:after {
    opacity: 1;
    visibility: visible;
  }

  &[data-tooltip-x="e"] {
    &:after {
      right: var(--tooltip-spacing);
    }
  }
  
  &[data-tooltip-x="w"] {
    &:after {
      left: var(--tooltip-spacing);
    }
  }

  &[data-tooltip-y="n"] {
    &:after {
      bottom: var(--tooltip-spacing);
    }
  }

  &[data-tooltip-y="s"] {
    &:after {
      bottom: initial;
      top: var(--tooltip-spacing);
    }
  }

  &[data-tooltip-y] {
    &[data-tooltip-x="w"]:after {
      left: 0;
    }

    &[data-tooltip-x="e"]:after {
      right: var(--spacing-3-minus);
    }
  }
}
