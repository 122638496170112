.dark-theme {
  portal-example header .skeleton-line {
    --example-bg-color: var(--doc-code-background-color);

    &::after {
      background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.1) 40%,
        rgba(255, 255, 255, 0.2) 60%,
        rgba(255, 255, 255, 0)
      );
    }
  }
}
