code {
  &.hljs {
    color: currentColor;
    background: none;
    line-height: 1.5;

    .hljs-string,
    .hljs-symbol,
    .hljs-template-tag,
    .hljs-template-variable,
    .hljs-addition {
      color: var(--doc-base-color);
    }

    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-title,
    .hljs-section,
    .hljs-built_in,
    .hljs-doctag,
    .hljs-type,
    .hljs-tag,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class,
    .hljs-strong {
      color: var(--doc-base-dark-color);
      font-weight: bolder;
    }
  }
}

pre code.hljs {
  padding: 0;
}
