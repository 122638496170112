h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: var(--title-line-height);
  margin: 0 0 var(--spacing-3);
}

p {
  margin: 0 0 var(--spacing-3);
}

a {
  text-decoration-skip: auto;
}

hr {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: var(--spacing-6) 0;
}

b {
  font-weight: var(--font-weight-semibold);
}
