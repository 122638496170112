:root {
  --doc-base-color: var(--base-color);
  --doc-base-light-color: var(--lighter-blue);
  --doc-base-dark-color: var(--azure-blue);
  --doc-success-color: var(--medium-green);
  --doc-error-color: var(--red);
  --doc-warning-color: var(--bright-orange);
  --doc-warning-light-color: var(--light-red);
  --doc-nested-background-color: var(--gray-10);

  --transparent-bg-color: rgba(255, 255, 255, 0);

  --scroll-shadow-gradient: rgba(0, 0, 0, 0.2);
  --scroll-shadow-size: 0.75em;

  --base-gap: var(--spacing-8);
  --doc-inline-spacing: var(--spacing-12);
  --doc-block-spacing: var(--spacing-16);

  @media (max-width: 850px) {
    --doc-inline-spacing: var(--spacing-6);
  }
}
